import BigPageLink from "components/BigPageLink";
import Footer from "components/Footer";
import PageHeading from "components/PageHeading";
import PeopleBlock from "components/agency/PeopleBlock";
import ServicesBlock from "components/agency/ServicesBlock";
import SimpleCarousel from "components/agency/SimpleCarousel";
import TextBlock from "components/blocks/TextBlock";
import TextWithImageBlock from "components/blocks/TextWithImageBlock";
import PrinciplesItemRenderer from "components/carousel-item-renderers/PrinciplesItemRenderer";
import CaseHero from "components/case/Hero";
import FormBlock from "components/events/FormBlock";
import ExpectationsBlock from "components/internships/ExpectationsBlock";
import Hero from "components/splurge/Hero";
import SplurgeForm from "components/splurge/SplurgeForm";
import { normal } from "components/typography/sizes";
import { graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import React from "react";
import { Box } from "rebass";
import { withTheme } from "styled-components";
import BaseTemplate from "templates/base";

const renderBlock = (
  idx,
  block,
  ready,
  onHeroImageLoaded,
  theme,
  hasUsedTextBlock = false
) => {
  const apiKey = block && block.model ? block.model.apiKey : null;
  let Cmp = null;
  let extras = {};

  switch (apiKey) {
    case "case_study_hero":
      extras.client = { name: "Why We Buy" };
      if (!block.image) {
        extras.ready = ready;
        Cmp = PageHeading;
      } else {
        extras.onImageLoaded = onHeroImageLoaded;
        extras.ready = ready;
        extras.color = theme.colors.black;
        extras.paragraphColor = `black`;
        extras.bgColor = theme.colors.yellow;
        extras.marginBottom = true;
        Cmp = CaseHero;
      }
      extras.renderAtEnd = <SplurgeForm />;
      Cmp = CaseHero;
      break;
    case "internships_hero":
      if (!block.image) {
        extras.ready = ready;
        Cmp = PageHeading;
      } else {
        extras.onImageLoaded = onHeroImageLoaded;
        extras.ready = ready;
        Cmp = Hero;
      }
      break;
    case "form_block":
      Cmp = FormBlock;
      extras = {
        contentProps: {
          first: true,
          fontSize: normal,
          fontFamily: "Larish",
          fontWeight: "semibold",
          size: "small",
        },
      };
      break;
    case "expectations_block":
      Cmp = ExpectationsBlock;
      extras = {
        contentProps: {
          first: true,
          size: "small",
        },
      };
      break;
    case "text_block":
      Cmp = TextBlock;
      extras = {
        contentProps: {
          vcenter: true,
          css: `
          text-align:${block.align};
          & p, & h3{
            max-width:80ch;
           ${block.align === `center` && `margin: 0 auto;`}
          }
          & h2 {
            font-size: 100px !important;
            font-family: 'Druk' !important;
            line-height: 0.95 !important;
            margin-bottom:0.2em;
            color: ${theme.colors.yellow};
            strong {
              color: ${theme.colors.yellow};
            }
            @media screen and (min-width:${
              parseInt(theme.breakpoints[0]) + 1
            }px){
              font-size: 150px !important;
            }
            @media screen and (min-width:${
              parseInt(theme.breakpoints[3]) + 1
            }px){
              font-size: 180px !important;
            }
          }
          & h3{
            font-family:'Larish';
            font-weight: 500;
            font-size:18px;
            margin-bottom:1.5em;
            @media screen and (min-width:${
              parseInt(theme.breakpoints[0]) + 1
            }px){
              font-size: 20px !important;
            }
            @media screen and (min-width:${
              parseInt(theme.breakpoints[3]) + 1
            }px){
              font-size: 20px !important;
            }
          }
        `,
        },
      };
      break;
    case "text_image_block":
      Cmp = TextWithImageBlock;
      extras = {
        fullWidth: true,
        contentProps: {
          vcenter: true,
          css: `
          & h2 {
            font-size: 80px !important;
            font-family: 'Druk' !important;
            line-height: 0.95 !important;
            margin-bottom:0.2em;
            color: ${theme.colors.yellow};
            strong {
              color: ${theme.colors.yellow};
            }
            @media screen and (min-width:${
              parseInt(theme.breakpoints[0]) + 1
            }px){
              font-size: 100px !important;
            }
            @media screen and (min-width:${
              parseInt(theme.breakpoints[3]) + 1
            }px){
              font-size: 120px !important;
            }
          }
          & h3{
            font-family:'Larish';
            font-weight: 500;
            font-size:20px;
            margin-bottom:1.5em;
            @media screen and (min-width:${
              parseInt(theme.breakpoints[0]) + 1
            }px){
              font-size: 24px !important;
            }
            @media screen and (min-width:${
              parseInt(theme.breakpoints[3]) + 1
            }px){
              font-size: 28px !important;
            }
          }
        `,
        },
      };
      break;
    case "principles_carousel":
      Cmp = SimpleCarousel;
      extras = {
        sideLabel: "Principles",
        nextArrowOffset: -3,
        showNumbers: false,
        nextArrowColor: theme.fgColor,
        big: true,
        ItemRenderer: PrinciplesItemRenderer,
      };
      break;
    case "services_block":
      Cmp = ServicesBlock;
      break;
    case "people_block":
      Cmp = PeopleBlock;
      break;
    case "big_page_link":
      Cmp = BigPageLink;
      extras = { big: true };
      break;
    default:
      break;
  }
  if (idx === 0) {
    extras.sectionProps = { ...extras.sectionProps, autoShow: true };
  }
  if (Cmp) {
    return <Cmp theme={theme} key={block.id} {...block} {...extras} />;
  }

  return null;
};

class PerspectivesPageTemplate extends BaseTemplate {
  constructor(props) {
    super(props);
    this.hasUsedTextBlock = false;
  }

  onHeroImageLoaded = () => {
    this.setPageReadyTimeout(1500);
  };

  render() {
    const { ready } = this.state;
    const { data, theme } = this.props;
    const { page } = data;
    const { seoMetaTags, body } = page;

    return (
      <React.Fragment>
        <HelmetDatoCms seo={seoMetaTags}>
          <link rel="preload" as="image" href="/loader-pink.gif" />
        </HelmetDatoCms>
        <Box position="relative" display="block" pt={[150, 150, 80]}>
          {body &&
            body.map((block, idx) =>
              renderBlock(
                idx,
                block,
                ready,
                this.onHeroImageLoaded,
                theme,
                this.hasUsedTextBlock
              )
            )}
        </Box>
        <Footer theme={theme} globals={this.props.pageContext.globals} />
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query {
    page: datoCmsPerspectivesPage {
      id
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      body {
        ... on DatoCmsCaseStudyHero {
          model {
            apiKey
          }
          id
          heading
          paragraphNode {
            childMarkdownRemark {
              html
            }
          }
          image {
            url
            alt
            fluid(
              maxWidth: 1440
              imgixParams: { auto: "compress,format", fm: "jpg", q: 75 }
            ) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
        ... on DatoCmsInternshipsHero {
          model {
            apiKey
          }
          id
          heading
          icon {
            url
            alt
            fluid(maxWidth: 600, imgixParams: { auto: "compress,format" }) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
          image {
            url
            alt
            fluid(
              maxWidth: 1440
              imgixParams: { auto: "compress,format", fm: "jpg", q: 90 }
            ) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
        ... on DatoCmsTextBlock {
          id
          model {
            apiKey
          }
          showAside
          asidePrefix
          asideHeading
          ctaLink
          ctaLabel
          align
          groupWithNextBlock
          contentNode {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on DatoCmsTextImageBlock {
          id
          model {
            apiKey
          }
          layout
          groupWithNextBlock
          fullBleedImage
          image {
            url
            alt
            fluid(
              maxWidth: 1366
              imgixParams: { auto: "compress,format", fm: "jpg", q: 90 }
            ) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
          ctaLink
          ctaLabel
          contentNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;

export default withTheme(PerspectivesPageTemplate);
